import React from "react"
// import { Link } from "gatsby"
import BaseLayout from "./../Layouts/BaseLayout"
import ContactForm from "./../components/ContactForm/ContactForm"
import Head from "./../components/Head/Head"
// import Colors from "./../../Theme/theme"
// import { MdClose } from "react-icons/md"

const Contact = props => {
  const paraStyles = {
    fontSize: "2.3em",
    fontWeight: 300,
    textAlign: "center",
    marginBottom: "0",
  }
  return (
    <BaseLayout showCloseIcon={true}>
      <Head pageTitle="Contact" />
      <br />
      <br />
      <div className="row wrap mt-5 pt-5">
        <div className="col-12 col-sm-11 col-md-9 col-lg-7 mx-auto mb-4">
          <p style={paraStyles}>Share your thoughts & ideas.</p>
          <p style={paraStyles}>I am always up for a chat.</p>
        </div>
        <ContactForm />
      </div>
    </BaseLayout>
  )
}

export default Contact

// .credit__card__app__form input {
//   border: 1px solid white;
//   padding: 0% 1% 1% 1%;
//   /* border-top: 1px solid #eee; */
//   border-bottom: 1px solid #eee;
//   margin: 0% 1% 1% 1%;
//   padding-left: 2%;
//   border-radius: 10px;
//   font-size: 0.9em;
// }

// .credit__card__app__form input:focus {
//   outline: none;
//   /* background: #eee */
// }

// .credit__card__app__form label {
//   font-size: 0.7em;
//   padding-left: 3%;
//   padding-bottom: 0.5%;
//   color: #939393;
//   margin-top: 1.5%;
// }

// .credit__card__app__form button {
//   padding: 3% 4%;
//   background: royalblue;
//   color: white;
//   width: 50%;
//   font-size: 0.9em;
//   border: none;
//   margin: 2%;
//   border-radius: 5px;
//   cursor: pointer;
//   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
// }

// .credit__card__app__form button:hover {
//   background: rgb(94, 123, 211);
// }

// input:focus::placeholder {
//   opacity: 0;
// }
