import React, { Component } from "react"
import Colors from "./../../Theme/theme"

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        fullName: "",
        email: "",
        details: "",
      },
    }
  }

  handleOnChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  render() {
    const labelStyle = {
      fontSize: "1.1em",
      paddingLeft: "1%",
      fontWeight: "200",
    }
    return (
      <div className="col-12 col-sm-11 col-md-9 col-lg-7 mx-auto">
        <form
          name="contactForm"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className="credit__card__app__form"
        >
          <input type="hidden" name="form-name" value="contactForm" />

          <div>
            <label style={labelStyle} htmlFor="fullName">
              Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              className="form-control mb-4"
              required={true}
              onChange={this.handleOnChange.bind(this, "fullName")}
            ></input>
          </div>

          <div>
            <label style={labelStyle} htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control mb-4"
              required={true}
              onChange={this.handleOnChange.bind(this, "email")}
            ></input>
          </div>

          <div>
            <label style={labelStyle} htmlFor="additionalDetails">
              Additional Details
            </label>
            <textarea
              name="additionalDetails"
              id="additionalDetails"
              cols="30"
              rows="10"
              className="form-control"
              required={true}
              onChange={this.handleOnChange.bind(this, "details")}
            ></textarea>
          </div>

          <div className="mt-4 text-center">
            <button
              className="btn btn-md px-5"
              style={{ backgroundColor: Colors.primary, color: "white" }}
            >
              Submit
            </button>
          </div>
        </form>
        <br />
        <br />
      </div>
    )
  }
}

export default ContactForm
